import React from 'react';

import Layout from '../components/layout';
import { Content, Footer, FooterLinkNext, Header, Page } from '../components/page';
import Seo from '../components/seo';

export default () => (
  <Layout>
    <Seo title="Password changed successfully" />

    <Page type="success">
      <Header closeUrl="/lessons">log in to Pilsner Urquell guide </Header>
      <Content center={true}>
        <h1 className="h2 growing text-transform-uppercase">
          Password changed successfully
        </h1>
      </Content>
      <Footer>
        <FooterLinkNext to="/login">log in</FooterLinkNext>
      </Footer>
    </Page>
  </Layout>
)
